export const environment = {
  production: false,
  serviceHeader: "A3685656-C9CC-4974-BD22-B027ED410A2C",
  backendRegisterUrl: 'https://api-docfis-hml.internal.americanas.io/docfis/auth',
  backendSyncUrl: 'https://api-docfis-hml.internal.americanas.io/docfis/sync',
  backOfficeUrl: 'https://api-docfis-hml.internal.americanas.io/docfis/backoffice',

  cognitoSSOAppClientId: '3baqkqndpmn2tate02o2aoups8',
  cognitoSSOAppSecret: 'hps8c6abii3if10n3ceoma7kiq9pkfkesmhq3e0c0ig7j1f7d94',
  cognitoAmeIdpName: "AmeDigitalProvider",
  cognitoTokenUrl: 'https://hml-docfis.auth.us-east-1.amazoncognito.com/oauth2/token',
  cognitoInfoUrl: 'https://hml-docfis.auth.us-east-1.amazoncognito.com/oauth2/userInfo',
  cognitoAuthorizeUrl: 'https://hml-docfis.auth.us-east-1.amazoncognito.com/oauth2/authorize',

  cognitoRedirectUrl: 'https://docfis-hml.internal.americanas.io/',
};