import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ResultPaginator } from 'src/app/shared/paginator/model/paginator.result';
import { PaginatorGenericService } from 'src/app/shared/paginator/service/paginator.generic.service';
import { environment } from 'src/environments/environment';
import { Store } from '../../register/models/store.model';


@Injectable()
export class StoreService implements PaginatorGenericService<Store> {
    constructor(private http: HttpClient) {

    }
    searchById(id: any): Observable<ResultPaginator<Store>> {
        throw new Error('Method not implemented.');
    }
    search(filter: String, limit: number, lastsKeys: string): Observable<ResultPaginator<Store>> {
        return this.http.get(`${environment.backOfficeUrl}/store/list-by-seller?limit=${limit}&${filter}&lastKeys=${lastsKeys ?? ''}`).pipe(map((data: any) => {
            return data;
        }))
    }
    setContingencyByStore(cnpj: String, modo: String) : Observable<ResultPaginator<Store>>{
        console.log(`setContingencyByStore: ${cnpj} ${modo}`)
        return this.http.put(`${environment.backOfficeUrl}/store/${cnpj}/contingency/${modo}`,null).pipe(map((data: any) => {
            return data;
        }))
    }
    setContingencyByUf(uf: String, modo: String) : Observable<ResultPaginator<Store>>{
        console.log(`setContingencyByUf: ${uf} ${modo}`)
        return this.http.put(`${environment.backOfficeUrl}/store/${uf}/${modo}`,null).pipe(map((data: any) => {
            return data;
        }))
    }
}