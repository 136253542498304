import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { RenewPasswordComponent } from './authentication/renew-password/renew-password.component';
import { canActivateForRoles } from './authentication/services/authentication.guard';
import { TokenResolverService } from './authentication/services/token-resolver.service';
import { HomeComponent } from './home/home.component';
import { RejectionsComponent } from './seller/rejections/rejections.component';

const routes: Routes = [
  { path: '', component: HomeComponent, ...canActivateForRoles(["ANY"]), pathMatch: 'full'},
  { path: 'seller', loadChildren: () => import('./seller/seller.module').then(mod => mod.SellerModule),...canActivateForRoles(["SELLER"])},
  { path: 'nfce', loadChildren: () => import('./nfce/nfce.module').then(mod => mod.NfceModule),...getDataForBreadcrumb(["SELLER","SUPPORT","DEV","CUSTOMER"], "Pesquisa de notas fiscais") },
  { path: 'login', component: AuthenticationComponent, resolve: {user:TokenResolverService}  },
  { path: 'certificate/register', loadChildren: () => import('./seller/certificate/certificate.module').then(mod => mod.CertificateModule), ...getDataForBreadcrumb(["SELLER","SUPPORT"], "Certificado digital")},
  { path: 'csc', loadChildren: () => import('./seller/csc/csc.module').then(mod => mod.CscModule), ...getDataForBreadcrumb(["SELLER","SUPPORT"], "Gestão de CSC")},
  { path: 'rejections', component: RejectionsComponent, ...canActivateForRoles(["SELLER"]) },
  { path: 'store/list', loadChildren: () => import('./store/list/store.list.module').then(mod => mod.StoreListModule), ...getDataForBreadcrumb(["SELLER","SUPPORT","DEV","CUSTOMER"], 'Gestão de Lojas')},
  { path: 'store/register', loadChildren: () => import('./store/register/register.module').then(mod => mod.RegisterModule), ...getDataForBreadcrumb(["SELLER","SUPPORT","DEV"], "Cadastrar nova loja")},
  { path: 'support/uf', loadChildren: () => import('./support/uf/uf.module').then(mod => mod.UfModule), ...getDataForBreadcrumb(["SELLER","SUPPORT"], "Alterar modo de emissão por UF")},
  { path: 'support/loja', loadChildren: () => import('./support/loja/loja.module').then(mod => mod.LojaModule), ...getDataForBreadcrumb(["SUPPORT"], "Alterar modo de emissão por Loja")},
  { path: 'renew-password', component: RenewPasswordComponent },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function getDataForBreadcrumb(roles: string[], breadcrumbName: string | any) {
  const breadcrumb =  {info: breadcrumbName, alias: "label"};
  const activation = canActivateForRoles(roles);
  return { data: { roles: activation.data.roles, breadcrumb: breadcrumb}, canActivate: activation.canActivate};
}
